<template>
  <BaseDetailSimple
    :leave-guard="leaveGuard"
    :input-attributes="inputAttributes"
    :query-values="queryValues"
    entity-type="style"
    locale-section="pages.styles"
    field-section="styles"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "StyleDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        _name: style
        style
        sv
        en
        tpq
        taq
      `,
      inputAttributes: {
        style: { required: true },
        sv: { required: true },
        en: { required: true },
        tpq: { type: "number" },
        taq: { type: "number" }
      }
    };
  }
};
</script>
